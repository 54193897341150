import { Container, VStack } from "@chakra-ui/react";
import NavigationBar from "./NavigationBar";
import ProtectedRoutes from "./ProtectedRoutes";

const Layout: React.FC = () => {
  return (
    <VStack height="100vh">
      <NavigationBar />
      <Container maxW="container.lg">
        <VStack>
          <ProtectedRoutes />
        </VStack>
      </Container>
    </VStack>
  );
};

export default Layout;
