export const getFromLocalStorage = (key: string) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : value;
};

export const populateLocalStorage = (data: { [key: string]: any }) => {
  Object.keys(data).map((key) => localStorage.setItem(key, JSON.stringify(data[key])));
};

export const removeFromLocalStorage = (keys: string[]) => {
  keys.map((key) => localStorage.removeItem(key));
};
