import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { css, Global } from "@emotion/react";

import theme from "./theme";
import { store } from "store";

import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import "focus-visible/dist/focus-visible";
import App from "./App";

const GlobalStyles = css`
  /** This will hide the focus indicator if the element receives focus via the mouse,
      but it will still show up on keyboard focus. */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Global styles={GlobalStyles} />
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
