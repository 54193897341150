import React, { ReactNode } from "react";
import { Route, Redirect } from "react-router-dom";
import useLocalStorage from "utils/hooks/useLocalStorage";

interface Props {
  path: string;
  children: ReactNode;
}

const PrivateRoute: React.FC<Props> = ({ children, ...rest }: Props) => {
  const [isAuthenticated] = useLocalStorage("isAuthenticated", false);

  const handleRender = (location: any) => {
    if (isAuthenticated) return children;
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  };

  return <Route {...rest} render={({ location }) => handleRender(location)} />;
};

export default PrivateRoute;
