import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: State.Party = {
  partyModeModalOpen: false,
  isUserPartying: false,
  activeParty: null,
};

export const partySlice = createSlice({
  name: "party",
  initialState,
  reducers: {
    togglePartyModeModal: (state, action: PayloadAction<boolean>) => {
      state.partyModeModalOpen = action.payload;
    },
    toggleIsUserPartying: (state, action: PayloadAction<boolean>) => {
      state.isUserPartying = action.payload;
    },
    setActiveParty: (state, action: PayloadAction<Api.PartyEvent | null>) => {
      state.activeParty = action.payload;
    },
  },
});

export const { togglePartyModeModal, setActiveParty, toggleIsUserPartying } = partySlice.actions;

export default partySlice.reducer;
