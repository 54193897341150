import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { login } from "components/services/authService";

import {
  Button,
  Center,
  Heading,
  VStack,
  Input,
  FormControl,
  FormLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import useLocalStorage from "utils/hooks/useLocalStorage";

interface FormData {
  password: string;
}

const LoginView: React.FC = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthenticated] = useLocalStorage("isAuthenticated", false);

  const bg = useColorModeValue("white", "baseDark.600");
  const boxShadow = useColorModeValue("0 4px 8px 0 rgba(0,0,0,0.2)", "");

  const { register, handleSubmit, reset } = useForm<FormData>({
    mode: "onChange",
  });

  const toggleFormType = () => {
    setIsAdmin(!isAdmin);
    reset();
  };

  const onSubmit = async (values: FormData) => {
    const { password } = values;
    setLoading(true);
    await login({ password, isAdmin });
    setLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  });

  return (
    <Center h="100vh">
      <VStack w="18rem" p={5} borderRadius={5} spacing={6} bg={bg} boxShadow={boxShadow}>
        <Heading size="md" alignSelf="start">
          {`Kirjaudu: ${isAdmin ? "JuMaLa" : "B-henkilö"}`}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isRequired>
            <FormLabel>Salasana</FormLabel>
            <Input type="password" {...register("password")} />
          </FormControl>
          <Button type="submit" isLoading={loading} isFullWidth mt={8}>
            Kirjaudu
          </Button>
        </form>
        <Button size="xs" variant="link" onClick={toggleFormType}>
          {isAdmin ? "Et ole JuMaLa? Paina tästä." : "JuMaLa? Paina tästä."}
        </Button>
      </VStack>
    </Center>
  );
};

export default LoginView;
