import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { auth } from "firebase";
import { onAuthStateChanged } from "firebase/auth";
import useLocalStorage from "utils/hooks/useLocalStorage";

import { Spinner } from "@chakra-ui/react";
import LoginView from "./views/LoginView";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "components/Layout";

const App: React.FC = () => {
  const [, setIsAuthenticated] = useLocalStorage("isAuthenticated", false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => setIsAuthenticated(!!user));
  }, [setIsAuthenticated]);

  return (
    <Suspense
      fallback={<Spinner size="lg" position="absolute" top="50%" left="50%" zIndex={999} />}
    >
      <Router>
        <Switch>
          <Route exact path="/login" component={LoginView} />
          <PrivateRoute path="/">
            <Layout />
          </PrivateRoute>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;
