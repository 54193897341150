import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialDrinkDetails: State.DrinkDetails = {
  userId: "",
  amount: 1,
  otherDrink: "",
};

const initialState: State.Drink = {
  selectedDrinkIndex: -1,
  selectedDrinkDetails: initialDrinkDetails,
  markDrinkToParty: true,
};

export const drinkSlice = createSlice({
  name: "drink",
  initialState,
  reducers: {
    setSelectedDrinkIndex: (state, action: PayloadAction<number>) => {
      state.selectedDrinkIndex = action.payload;
    },
    setSelectedDrinkDetails: (state, action: PayloadAction<State.DrinkDetails>) => {
      state.selectedDrinkDetails = action.payload;
    },
    toggleMarkDrinkToParty: (state, action: PayloadAction<boolean>) => {
      state.markDrinkToParty = action.payload;
    },
    incrementDrinkDetailsAmount: (state) => {
      state.selectedDrinkDetails = {
        ...state.selectedDrinkDetails,
        amount: state.selectedDrinkDetails.amount + 1,
      };
    },
    decrementDrinkDetailsAmount: (state) => {
      state.selectedDrinkDetails = {
        ...state.selectedDrinkDetails,
        amount: state.selectedDrinkDetails.amount - 1,
      };
    },
    setDrinkDetailsUserId: (state, action: PayloadAction<string>) => {
      state.selectedDrinkDetails = {
        ...state.selectedDrinkDetails,
        userId: action.payload,
      };
    },
    setDrinkDetailsOtherDrink: (state, action: PayloadAction<string>) => {
      state.selectedDrinkDetails = {
        ...state.selectedDrinkDetails,
        otherDrink: action.payload,
      };
    },
  },
});

export const {
  setSelectedDrinkIndex,
  setSelectedDrinkDetails,
  toggleMarkDrinkToParty,
  incrementDrinkDetailsAmount,
  decrementDrinkDetailsAmount,
  setDrinkDetailsUserId,
  setDrinkDetailsOtherDrink,
} = drinkSlice.actions;

export default drinkSlice.reducer;
