import { logout } from "./services/authService";

import {
  Flex,
  Heading,
  Spacer,
  HStack,
  Button,
  Icon,
  IconButton,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { FaSun, FaMoon } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { ReactComponent as BertilIcon } from "assets/Bertil_naama.svg";
import useLocalStorage from "utils/hooks/useLocalStorage";

const NavigationBar: React.FC = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  const [isSmallScreen] = useMediaQuery("(max-width: 512px)");

  const [activeUser] = useLocalStorage("userId", "");
  const [isAdmin] = useLocalStorage("isAdmin", false);

  const bg = useColorModeValue("white", "baseDark.600");
  const boxShadow = useColorModeValue("0 0px 8px 1px #e9e9e9d1, 0px 1px 1px 0px #70767e78", "");

  const handleLogout = async () => await logout();

  if (activeUser === "") return null;

  return (
    <Flex w="100%" alignItems="center" bg={bg} p={2} boxShadow={boxShadow}>
      <Icon as={BertilIcon} boxSize={isSmallScreen ? 12 : 16} mx={2} />
      <Heading size={isSmallScreen ? "sm" : "md"}>BIIKKILISTA</Heading>
      <Spacer />
      <HStack gap={{ sm: 0, md: "0.5rem", lg: "1rem" }}>
        <Heading size="sm">{isAdmin ? "JuMaLa" : `#${activeUser}`}</Heading>
        <IconButton
          aria-label="toggle_color_mode"
          variant={isSmallScreen ? "ghost" : "solid"}
          isRound
          icon={colorMode === "light" ? <FaSun /> : <FaMoon />}
          onClick={toggleColorMode}
        />
        <IconButton
          aria-label="logout-button-icon-only"
          variant="ghost"
          title="Kirjaudu ulos"
          sx={{ display: { sm: "flex", md: "none" } }}
          onClick={handleLogout}
        >
          <MdLogout size={24} />
        </IconButton>
        <Button
          rightIcon={<MdLogout size={24} />}
          sx={{ display: { sm: "none", md: "flex" } }}
          onClick={handleLogout}
        >
          KIRJAUDU ULOS
        </Button>
      </HStack>
    </Flex>
  );
};

export default NavigationBar;
