import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

interface ExtendedWindow extends Window {
  FIREBASE_APP_CHECK_DEBUG_TOKEN: boolean | string | undefined;
}

declare const window: ExtendedWindow;

const siteKey = process.env.REACT_APP_APP_CHECK_SITE_KEY || "";
const appcheckDebugToken = process.env.REACT_APP_APPCHECK_DEBUG_TOKEN || "";

window.FIREBASE_APP_CHECK_DEBUG_TOKEN = appcheckDebugToken;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const database = firebaseApp.firestore();

// Initialize auth
const auth = getAuth(firebaseApp);

// Initialize analytics
const analytics = getAnalytics(firebaseApp);

// Initialize app check
const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider(siteKey),
  isTokenAutoRefreshEnabled: true,
});

export { auth, database, appCheck, analytics };
