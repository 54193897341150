import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import useLocalStorage from "utils/hooks/useLocalStorage";

const AdminView = React.lazy(() => import("../views/AdminView"));
const MainView = React.lazy(() => import("../views/MainView"));

const ProtectedRoutes: React.FC = () => {
  const [isAdmin] = useLocalStorage("isAdmin", false);
  return (
    <Switch>
      <Route path="/admin">{!isAdmin ? <Redirect to="/" /> : <AdminView />}</Route>
      <Route path="/">{isAdmin ? <Redirect to="/admin" /> : <MainView />}</Route>
    </Switch>
  );
};

export default ProtectedRoutes;
