import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "slices/usersSlice";
import adminReducer from "slices/adminSlice";
import partyReducer from "slices/partySlice";
import drinkReducer from "slices/drinkSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["admin/setFilterStartDate", "admin/setFilterEndDate"],
        ignoredPaths: ["admin.filters"],
      },
    }),
  reducer: {
    users: usersReducer,
    admin: adminReducer,
    party: partyReducer,
    drink: drinkReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
