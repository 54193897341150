import { createStandaloneToast } from "@chakra-ui/react";
import { UseToastOptions } from "@chakra-ui/react";
import customTheme from "theme";

interface ToastData {
  status: UseToastOptions["status"];
  title: string;
  description?: string;
}

const toast = createStandaloneToast({ theme: customTheme });

export const handleToast = (toastData: ToastData) => {
  const { status, title, description } = toastData;
  toast({
    title,
    description,
    status,
    duration: 5000,
    isClosable: true,
    position: "bottom-left",
  });
};

export const getPartyEventToastData = (
  context: string,
  data: Api.PartyEvent,
  drinkData?: Api.Drink
): ToastData => {
  switch (context) {
    case "addPartyParticipant-success":
      const newUser = data.participants[data.participants.length - 1];
      return {
        status: "success",
        title: `#${newUser} lisätty bilelistalle!`,
      };
    case "addPartyParticipant-fail":
      return {
        status: "error",
        title: "Osallistujan lisäys epäonnistui!",
      };
    case "removePartyParticipant-success":
      return {
        status: "success",
        title: `Jäsen poistettu bilelistalta!`,
      };
    case "removePartyParticipant-fail":
      return {
        status: "error",
        title: "Osallistujan poisto epäonnistui!",
      };
    case "addPartyDrink-success":
      /** drinkData always exists when updating party drink events */
      return {
        status: "success",
        title: `${drinkData!.amount === 1 ? "Juoma" : "Juomat"} lisätty bilelistalle!`,
        description: `Juoma: ${drinkData!.name}, Määrä: ${drinkData!.amount}`,
      };
    case "addPartyDrink-fail":
      return {
        title: "Merkkaus bilelistalle epäonnistui!",
        status: "error",
      };
    case "deactivateParty-success":
      return {
        status: "success",
        title: "Bileet lopetettu!",
        description: `Osallistujat: ${data.participants.join(", ")}`,
      };
    case "deactivateParty-fail":
      return {
        status: "error",
        title: "Bilelistan lopetus epäonnistui!",
      };
    default:
      return {
        title: "Default info",
        status: "info",
      };
  }
};
