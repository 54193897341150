import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: State.Users = {
  userIds: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserIds: (state, action: PayloadAction<string[]>) => {
      state.userIds = action.payload;
    },
  },
});

export const { setUserIds } = usersSlice.actions;

export default usersSlice.reducer;
