import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: State.Admin = {
  fetchedWithCurrentFilters: {
    drink: false,
    party: false,
  },
  filters: { fetchedStartDate: null, fetchedEndDate: null },
};

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    setAdminFilters: (state, action: PayloadAction<State.AdminFilters>) => {
      state.filters = action.payload;
    },
    setFetchedStartDate: (state, action: PayloadAction<string | null>) => {
      state.filters.fetchedStartDate = action.payload;
    },
    setFetchedEndDate: (state, action: PayloadAction<string | null>) => {
      state.filters.fetchedEndDate = action.payload;
    },
    toggleFetchedWithCurrentFilters: (state, action: PayloadAction<State.AdminFetchFilters>) => {
      state.fetchedWithCurrentFilters = action.payload;
    },
  },
});

export const {
  setAdminFilters,
  setFetchedStartDate,
  setFetchedEndDate,
  toggleFetchedWithCurrentFilters,
} = adminSlice.actions;

export default adminSlice.reducer;
