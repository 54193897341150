import { FirebaseError } from "@firebase/util";
import { logEvent } from "firebase/analytics";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { handleToast } from "utils/toastUtils";
import { analytics, auth } from "firebase";
import { removeFromLocalStorage } from "utils/localStorageUtils";

const basicUser = process.env.REACT_APP_USERNAME_BMIES || "";
const adminUser = process.env.REACT_APP_USERNAME_JUMALA || "";

export const login = async ({ password, isAdmin }: Api.Login) => {
  const email = isAdmin ? adminUser : basicUser;
  try {
    await signInWithEmailAndPassword(auth, email, password);
    logEvent(analytics, "login");
  } catch (error) {
    console.error(error instanceof FirebaseError ? error.message : error);
    handleToast({
      status: "error",
      title: "Virhe kirjautumisessa!",
      description: error instanceof FirebaseError ? error.message : "Tuntematon virhe",
    });
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    removeFromLocalStorage(["userId", "isAdmin"]);
  } catch (error) {
    console.error(error instanceof FirebaseError ? error.message : error);
    handleToast({
      status: "error",
      title: "Virhe uloskirjautumisessa!",
      description: error instanceof FirebaseError ? error.message : "Tuntematon virhe",
    });
  }
};
