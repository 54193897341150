import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const customTheme = extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: false,
  },
  styles: {
    global: (props: any) => ({
      body: {
        bg: mode("#fafafa", "baseDark.800")(props),
      },
    }),
  },
  colors: {
    baseLight: {
      50: "#e1e1e1",
      100: "#e4e4e4",
      200: "#e8e8e8",
      300: "#eaeaea",
      400: "#efefef",
      500: "#f1f1f1",
      600: "#f4f4f4",
      700: "#f8f8f8",
      800: "#fafafa",
      900: "#fefefe",
    },
    baseDark: {
      50: "#646464",
      100: "#595959",
      200: "#545454",
      300: "#494949",
      400: "#444444",
      500: "#393939",
      600: "#343434",
      700: "#292929",
      800: "#242424",
      900: "#141414",
    },
  },
  breakpoints: {
    sm: "300px",
    md: "512px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },
  components: {
    div: {
      sizes: {
        sm: {
          fontSize: 16,
        },
      },
    },
    Button: {
      variants: {
        outline: (props: any) => ({
          ...(props.colorMode === "light"
            ? {
                bg: "white",
                _hover: { bg: "whiteAlpha.400" },
                _active: { bg: "whiteAlpha.700" },
              }
            : {
                bg: "baseDark.400",
                _hover: { bg: "baseDark.200" },
                _active: { bg: "baseDark.50" },
              }),
        }),
      },
    },
    AccordionItem: {
      baseStyle: ({ colorMode }: { colorMode: "light" | "dark" }) => ({
        mb: 2,
        border: "none",
        boxShadow:
          colorMode === "light" ? "0 1px 2px 1px #e9e9e9d1, 0px 1px 1px 0px #70767e78" : "",
      }),
    },
    AccordionButton: {
      baseStyle: ({ colorMode }: { colorMode: "light" | "dark" }) => ({
        minHeight: 45,
        backgroundColor: colorMode === "light" ? "white" : "baseDark.400",
        _hover: {
          backgroundColor: colorMode === "light" ? "whiteAlpha.700" : "baseDark.300",
        },
        _active: {
          backgroundColor: colorMode === "light" ? "whiteAlpha.400" : "baseDark.200",
        },
      }),
    },
    AccordionPanel: {
      baseStyle: ({ colorMode }: { colorMode: "light" | "dark" }) => ({
        backgroundColor: colorMode === "light" ? "white" : "baseDark.600",
      }),
    },
  },
});

export default customTheme;
